// @import './src/styles/variables';

@import '@fortawesome/fontawesome-svg-core/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Roboto:ital,wght@0,300;0,500;0,700;1,400;1,500&display=swap');

$base-dark-blue: #3b5998; //rgb(59, 89, 152);
$base-light-blue: #8b9dc3; //rgb(139, 157, 195)
$base-dark-grey: #dfe3ee; //RGB(223,227,238)
$base-light-grey: #f7f7f7; //RGB(247,247,247)
$base-background: #ffffff; //rgb(255, 255, 255);
$base-yellow: #e2b842;
$base-error-color: red;
$text-dark-grey: rgb(149, 144, 144);
$text-light-grey: rgb(191, 184, 184);
$text-white: #ffffff; //rgb(255, 255, 255);

@mixin font-text-user {
  font-family: 'Roboto', sans-serif;
}
@mixin header-h1 {
  font-size: 30px;
  font-weight: 400;
}
@mixin header-h1-semi-bold {
  font-size: 30px;
  font-weight: 600;
}
@mixin header-h2 {
  font-size: 25px;
  font-weight: 400;
}
@mixin header-h2-semi-bold {
  font-size: 25px;
  font-weight: 600;
}
@mixin header-h3 {
  font-size: 20px;
  font-weight: 400;
}
@mixin header-h3-semi-bold {
  font-size: 20px;
  font-weight: 600;
}

@mixin text-h2 {
  font-size: 15px;
  font-weight: 400;
}
@mixin text-h2-semi-bold {
  font-size: 15px;
  font-weight: 600;
}
@mixin text-h3 {
  font-size: 14px;
  font-weight: 400;
}
@mixin text-h3-semi-bold {
  font-size: 14px;
  font-weight: 600;
}

@mixin border-style-blue {
  border-width: 3px;
  border-style: solid;
  border-color: $base-dark-blue;
}
@mixin border-style-gray {
  border-width: 3px;
  border-style: solid;
  border-color: $base-dark-grey;
}
