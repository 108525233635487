@import './src/styles/variables';

.navigation-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  height: 80px;
  border-bottom: 5px solid $base-light-blue;
}
.navigation {
  &-bar {
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    background-color: $base-dark-blue;
    text-align: center;
    &__link {
      width: 20%;
      &-button {
        @include header-h2-semi-bold;
        text-decoration: none;
        color: $base-background;
        cursor: pointer;
      }
      &-button:hover {
        color: $base-dark-grey;
      }
      &-lng-btn {
        margin: 0 5px;
        border-radius: 3px;
        border-color: blanchedalmond;
        cursor: pointer;
        background-color: $base-background;
        color: $base-dark-blue;
      }
    }
  }
}
