@import './src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  @include border-style-gray;
  border-radius: 5px;
  margin: 30px auto;
}
.tab-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  &-btn {
    all: unset;
    background-color: $base-dark-grey;
    width: 100%;
    height: 80px;
    cursor: pointer;
    text-align: center;
    @include header-h3-semi-bold;
    color: $base-dark-blue;
    transition: background 0.5s ease;
    &-link {
      color: $base-dark-blue;
      width: 33.4%;
    }
  }
}
.tab-content {
  display: flex;
  flex-direction: column;
}

.active {
  background-color: $base-background;
  border-top: solid;
  border-top-color: $base-dark-blue;
}
