@import './src/styles/variables';
.todolist {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding-top: 100px;
    text-transform: capitalize;
  }
  &-input {
    @include border-style-blue;
    border-radius: 10px;
    &__btn-add {
      @include header-h3;
      width: 70px;
      height: 46px;
      background-color: $base-dark-blue;
      color: $base-background;
      border: none;
      cursor: pointer;
    }
    &__input-field {
      @include header-h3;
      width: 400px;
      height: 44px;
      color: $base-dark-blue;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }
  }
  &-todos__list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  &-todos__list-item {
    @include header-h2;
    color: $base-dark-blue;
    margin: 20px auto;
    background: none;
    border: none;
    text-transform: capitalize;
    cursor: pointer;
  }
}
input:focus {
  outline: none;
}

.no-data-message {
  @include header-h1-semi-bold;
  color: $base-dark-blue;
  text-align: center;
}
