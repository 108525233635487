@import './src/styles/variables';
.albums {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding-top: 0;
    text-transform: capitalize;
  }

  &-albums__list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  &-albums__list-item {
    @include header-h2;
    color: $base-dark-blue;
    margin: 20px auto;
    background: none;
    border: none;
    text-transform: capitalize;
    list-style-type: none;
  }
}
input:focus {
  outline: none;
}

.no-data-message {
  @include header-h1-semi-bold;
  color: $base-dark-blue;
  text-align: center;
}
