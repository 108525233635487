@import './src/styles/variables';

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__tittle {
    font-size: 200px;
    margin: 0;
    color: $base-dark-blue;
  }
  &__text {
    font-size: 50px;
    color: $base-dark-blue;
  }
}
